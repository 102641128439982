import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj16 = () => {
  return (
    <Layout>
      <SEO title="Dobble Game" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-16">
          <div className="py-1-heading">
            <h1>Dobble Game</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Dobble%20Game"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              In dobble game,
              <br />
              We have few cards, which have few no. of pictures (let,5 or 8) and
              <br />
              in those cards only one picture is common between any two of them.
              <br />
              <br />
              It is a two player game.
              <br />
              <br />
              Now in programming, lets stuck with concept, and make a change in
              game,
              <br />
              represent alphabets as symbols.
              <br />
              <br />
              The player will get random two cards (list) having definite no. of
              symbols
              <br />
              <br />
              So, sopt the similar one...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>String</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj16
